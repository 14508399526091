import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Company from '../components/company'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query CompanyTemplateQuery($id: String!) {
      company: sanityCompany(id: { eq: $id }) {
          title
          _id
          _key
          slug {
              current
          }
          _rawExcerpt
          link
          logo {
              asset {
                  _id
                  url
              }
          }
          brandHex {
              hex
          }
          location
          founders
          postImage {
              asset {
                  _id
                  url
              }
          }
          mainImage {
              asset {
                  _id
                  url
              }
          }
          person {
              _id
              name
              slug {
                  current
              }
          }
          sectors {
              _id
              title
          }
          stage {
              _id
              title
          }
      }
  }
`

const CompanyTemplate = props => {
  const { data, errors } = props
  const company = data && { ...data.company }
  return (
    <Layout hideContactBar hideAnnouncements palette='red'>
      {errors && <SEO title='GraphQL Error' />}
      {company && <SEO title={company.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {company && <Company {...company} />}
    </Layout>
  )
}

export default CompanyTemplate
